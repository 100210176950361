import React, { Component } from "react"
import Img from "gatsby-image"
import female from '../images/female_profile.jpg';
import male from '../images/male_profile.jpg';
import  Logo from '../images/logo.svg';
import { wrapGrid } from 'animate-css-grid'

import Layout from "../layouts"
import SEO from "../components/seo"
class AboutPage extends Component {
  componentDidMount() {
    // will automatically clean itself up when dom node is removed
      wrapGrid(this.grid, {easing: 'linear', duration: 350, stagger: 10});
  }
  render() {
    let page = this.props.data.wordpressPage
    let hero = page.acf.about_hero_image ? page.acf.about_hero_image.localFile.childImageSharp.fluid : false
    let members = page.acf.members
    function handleClick(event)  {
      let target = event.target;
      let opened = document.getElementsByClassName('member--expanded')      
      while (target.tagName !== "HTML") {
        if (target.classList.contains("member")) {
          if (opened.length == 0 ) {
            target.classList.add("member--expanded");
          } else if (target.classList.contains("member--expanded") && opened.length > 0) {
            opened[0].classList.toggle("member--expanded")
          } else {
            opened[0].classList.toggle("member--expanded")
            target.classList.add("member--expanded");
          }
          
          return;
        }
        target = target.parentElement;
      }
      
    }
    return (
        <Layout page="about-us" >
            <SEO title="Visit Films - About Us"/>
            <section className="section hero hero--about-us">
                <div className="container">
                <div className="hero__item">
                    {hero != null? <Img src={hero.src} fluid={hero} />: null}
                </div>
                </div>
            </section>
            <section className="section hero--text">
                <div className="container">
                <div className="hero__copy"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                    __html: page.acf.about_description,
                    }}
                    />
                </div>
            </section>
            <section className="section team-section">
            <h3 className="section__header"><span>The Team</span></h3>
                <div className="container">
                  <div className="members" ref={el => (this.grid = el)}>
                    {members.map((m, i) => {
                        let imgSrc = m.member_image? m.member_image.localFile.childImageSharp.fluid: false
                        
                        return <div className="member" key={"member_" + i} onClick={handleClick}>
                            <div className="member__profile">
                              <div className="member__image">
                                {imgSrc ? <img src={imgSrc.src} alt={m.member_name}/> : <img src={female} alt={m.member_name} />}
                              </div>
                              <div className="member__bio"> 
                                <p>{m.member_description}</p>
                              </div>
                              <div className="member__info">
                                <div className="member__name">{m.member_name}</div><div className="member__role">{m.member_role}</div>
                              </div>
                              
                            </div> 
                        </div>;
                    }
                    )}
                  </div>
                </div>
            </section>
        </Layout>
    )
  }
}
export default AboutPage

export const pageQuery = graphql`
  query {
    wordpressPage(slug: {eq: "about-us"}) {
        title
        acf {
            about_hero_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            about_description
            members {
              member_description
              member_image {
                localFile {
                    childImageSharp {
                      fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
              }
              member_name
              member_role
            }   
        }
    }
  }
`
